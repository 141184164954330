import React from "react";
import Select from "react-select";
import { Label } from "components/typography";
import { multipleClassNames } from "shared/utilities";
import styles from "./ReactSelect.module.scss";
import { ClipLoader } from "react-spinners"; // Optional: For loading spinner

export const ReactSelect = ({ className = [], label, formik, name, options = [], disabled, isLoading, loadingMessage = "Loading...", ...props }) => {
  const onChange = (option) => {

    if (name === "club" && option.clubId) {
      formik.setFieldValue(name, option.clubId);
      formik.setTouched(true);
    } else if (name === "fixture") {
      formik.setFieldValue(name, option.fixtureId);
      // formik.setTouched(true);
    } else {
      formik.setFieldValue(name, option.value);
      formik.setTouched(true);
    }
  };

  return (
    <Label label={label} disabled={disabled}>
      <Select
        className={multipleClassNames([styles.reactSelect, ...className])}
        options={options}
        onChange={onChange}
        value={options ? options.find((option) => option.value === formik.values[name]) : ""}
        isDisabled={disabled}
        isLoading={isLoading} // Pass the loading state here
        {...props}
        // Customize the loading state message
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () =>
            isLoading ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <ClipLoader size={20} color="#000" />
                <span style={{ marginLeft: 8 }}>{loadingMessage}</span>
              </div>
            ) : null,
        }}
      />
    </Label>
  );
};
